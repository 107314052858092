import Navbar from "./Navbar";
import {
  IconList,
  IconTools,
  IconActivityHeartbeat,
  IconScript,
  IconShieldCheck,
  IconSettings,
  IconShieldLock,
  IconHome,
  IconTable
} from "@tabler/icons-react";
import allmoxyIcon from "../../assets/allmoxy.ico";

const MainNavbar = ({ children }) => {
  const linkItems = [
    {
      name: "Home",
      path: "/",
      icon: IconHome
    },
    { name: "Orders", path: "/orders", icon: IconTable },
    { name: "Scheduling", icon: IconList, path: "/scheduling/" },
    { name: "Inventory", icon: IconTools, path: "/inventory/" },
    {
      name: "Quality Control",
      icon: IconShieldCheck,
      path: "/quality-control/"
    },
    { name: "Event Log", icon: IconActivityHeartbeat, path: "/event-log/" },
    { name: "Scripts", icon: IconScript, path: "/scripts/" },
    {
      path: "/allmoxy",
      name: "Allmoxy",
      icon: allmoxyIcon,
      subItems: [
        { path: "/allmoxy/products", name: "Products" },
        { path: "/allmoxy/validations", name: "Validations" },
        { path: "/allmoxy/settings", name: "Settings" }
      ],
      //   visible:
      //     integrations &&
      //     integrations.filter((x) => x.type === "Allmoxy").length === 1,
      permission: ["manageAllmoxyIntegration"]
    },
    {
      path: "/settings",
      name: "Settings",
      icon: IconSettings,
      subItems: [
        { path: "/settings/profile", name: "Profile" },
        { path: "/settings/limits", name: "Limits" },
        { path: "/settings/totals-groups", name: "Totals Groups" },
        { path: "/settings/order-flags", name: "Order Flags" },
        { path: "/settings/holidays", name: "Holidays" }
      ],
      permission: ["viewSettings"]
    },
    {
      path: "/administration",
      name: "Administration",
      icon: IconShieldLock,
      subItems: [
        { path: "/administration/overview", name: "Overview" },
        { path: "/administration/users", name: "Users" },
        { path: "/administration/groups", name: "Groups" },
        { path: "/administration/integrations", name: "Integrations" },
        { path: "/administration/api-tokens", name: "API Tokens" },
        {
          path: "/administration/regenerate-totals",
          name: "Regenerate Totals"
        },
        { path: "/administration/delete-order", name: "Delete Order" },
        { path: "/administration/export-data", name: "Export Data" }
      ],
      permission: ["viewUsers"]
    }
  ];

  return <Navbar linkItems={linkItems}>{children}</Navbar>;
};

export default MainNavbar;
