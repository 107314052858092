import Navbar from "./Navbar";
import {
  IconDashboard,
  IconTable,
  IconList,
  IconCalendar,
  IconFileExport,
  IconTools,
  IconHome
} from "@tabler/icons-react";

const SchedulingNavbar = ({ children }) => {
  const linkItems = [
    {
      name: "Home",
      path: "/",
      icon: IconHome
    },
    {
      path: "/scheduling/",
      name: "Scheduling",
      icon: IconList,
      permission: ["viewScheduling"]
    },
    {
      path: "/scheduling/daily-starts",
      name: "Daily Starts",
      icon: IconCalendar,
      permission: ["viewDailyStarts"]
    },
    {
      path: "/scheduling/exporters",
      name: "Exporters",
      icon: IconFileExport,
      permission: ["viewExporters"]
    },
    {
      path: "/scheduling/utilities",
      name: "Utilities",
      icon: IconTools,
      subItems: [{ path: "/scheduling/utilities/exporters", name: "Exporters" }]
    }
  ];

  return <Navbar linkItems={linkItems}>{children}</Navbar>;
};

export default SchedulingNavbar;
